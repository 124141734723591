import { UserManagerSettings } from 'oidc-client';
import {
  lassoProductionConfiguration,
  lassoStagingConfiguration,
} from '@amzn/dots-core-ui';

export interface Environment {
  hosts: string[];
  searchApiUrl: string;
  hostAgentServiceApiUrl: string;
  schedulingApiUrl: string;
  schedulerUrl: string;
  rootSchedulingApiUrl: string;
  userManagerSettings: UserManagerSettings;
}

const environments: Environment[] = [
  {
    // Alpha
    hosts: [
      'saurade.aka.corp.amazon.com',
      'yangaar.aka.corp.amazon.com',
      'hosts-alpha.dots.lab126.a2z.com',
      'server-alpha.dots.lab126.a2z.com',
      'localhost',
    ],
    searchApiUrl: 'https://search-beta.dots.lab126.a2z.com',
    hostAgentServiceApiUrl:
      'https://api.hosts-alpha.dots.lab126.a2z.com/api/v4',
    schedulingApiUrl: 'https://api.scheduler-alpha.dots.lab126.a2z.com/api/v4',
    schedulerUrl: 'https://scheduler-alpha.dots.lab126.a2z.com/scheduling',
    rootSchedulingApiUrl: 'https://api.scheduler-alpha.dots.lab126.a2z.com',
    userManagerSettings: lassoStagingConfiguration,
  },
  {
    // Beta
    hosts: [
      'saurade.aka.corp.amazon.com',
      'yangaar.aka.corp.amazon.com',
      'hosts-beta.dots.lab126.a2z.com',
      'server-beta.dots.lab126.a2z.com',
    ],
    searchApiUrl: 'https://search-beta.dots.lab126.a2z.com',
    hostAgentServiceApiUrl: 'https://api.hosts-beta.dots.lab126.a2z.com/api/v4',
    schedulingApiUrl: 'https://api.scheduler-beta.dots.lab126.a2z.com/api/v4',
    schedulerUrl: 'https://scheduler-beta.dots.lab126.a2z.com/scheduling',
    rootSchedulingApiUrl: 'https://api.scheduler-beta.dots.lab126.a2z.com',
    userManagerSettings: lassoStagingConfiguration,
  },
  {
    // Gamma
    hosts: [
      'hosts-gamma.dots.lab126.a2z.com',
      'server-gamma.dots.lab126.a2z.com',
    ],
    searchApiUrl: 'https://search.dots.lab126.a2z.com',
    hostAgentServiceApiUrl:
      'https://api.hosts-gamma.dots.lab126.a2z.com/api/v4',
    schedulingApiUrl: 'https://api.scheduler.dots.lab126.a2z.com/api/v4',
    schedulerUrl: 'https://scheduler.dots.lab126.a2z.com/scheduling',
    rootSchedulingApiUrl: 'https://api.scheduler.dots.lab126.a2z.com',
    userManagerSettings: lassoStagingConfiguration,
  },
  {
    // Prod
    hosts: ['hosts.dots.lab126.a2z.com', 'server.dots.lab126.a2z.com'],
    searchApiUrl: 'https://search.dots.lab126.a2z.com',
    hostAgentServiceApiUrl: 'https://api.hosts.dots.lab126.a2z.com/api/v4',
    schedulingApiUrl: 'https://api.scheduler.dots.lab126.a2z.com/api/v4',
    schedulerUrl: 'https://scheduler.dots.lab126.a2z.com/scheduling',
    rootSchedulingApiUrl: 'https://api.scheduler.dots.lab126.a2z.com',
    userManagerSettings: lassoProductionConfiguration,
  },
];

const match = environments.filter(
  (config) => config.hosts.indexOf(window.location.hostname) > -1
);
if (match.length === 0) {
  throw new Error(`${window.location.hostname} is not configured`);
}

export const [environment] = match;
